@import url('https://fonts.googleapis.com/css2?family=Avenir:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');
 

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Avenir';
}

html,
body {
  overflow-x: hidden;
  font-family: 'Avenir'
}

.scrol {
  visibility: hidden;
}

.noScrol {
  visibility: visible;
}

.Nscrol {
  display: none;
}

.NnoScrol {
  display: "";
}

a:hover {
  color: black;
}

.Links {
  color: white;
  font-size: large;
}


:root {
  --mainColor: white;
  --mainColorLight: #5767aa;
  --secondaryColor: black;
  --textColor: white;
}

header {
  box-shadow: 0.5px 0.2px whitesmoke;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  color: var(--textColor);
  position: fixed;
}

nav a {
  /* border-radius: 10%; */
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  /* background-color: #e1b6b4; */
  margin-left: 20px;
  padding: 5px;
  cursor: pointer;
  width: 170px;
  font-size: large;
  text-align: center;
}

nav a:hover {
  color: black;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #e1b6b4;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    /* background-color: var(--mainColor); */
    transition: 0.5s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 570px) {
  .fimg {
    display: none;
  }

  .b {
    align-items: center;
    justify-content: center;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown {
  /* //position: relative; */
  display: inline-block;
  width: 170px;
  background-color: #e1b6b4;
  /* padding: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.dropdown-content a {
  text-align: center;
  color: black;
  padding: 6px 6px;
  font-size: large;
  text-decoration: none;
  display: block;
  background-color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content a {
  margin: 0px;
}

.dropdown:hover .dropdown-content {
  display: block;
  background-color: white;
}

.dropdown:hover .dropbtn {
  background-color: #e1b6b4;
  padding: 0px;
}

.scrol {
  visibility: hidden;
}

.noScrol {
  visibility: visible;
}

.Nscrol {
  display: none;
}

.NnoScrol {
  display: "";
}

a:hover {
  color: black;
}

.Links {
  color: white;
  font-size: large;
}

:root {
  --mainColor: rgb(248, 249, 250);
  --mainColorLight: #5767aa;
  --secondaryColor: black;
  --textColor: white;
}

header {
  box-shadow: 0.5px 0.2px whitesmoke;
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 2rem;
  background-color: white;
  color: var(--textColor);
  position: fixed;
  z-index: 99999;
}

nav a {
  /* border-radius: 10%; */
  /* margin: 0 1rem; */
  color: var(--textColor);
  text-decoration: none;
  background-color: #e1b6b4;
  margin-left: 30px;
  padding: 5px;
  cursor: pointer;
  width: 170px;
  font-size: large;
  text-align: center;
}

nav a:hover {
  color: black;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #e1b6b4;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 0.5s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

.link{
  margin-left: 70px;
}
.link1{
  margin-right: 20px;
}

@media only screen and (max-width: 570px) {
  .fimg {
    display: none;
  }

  .b {
    align-items: center;
    justify-content: center;
  }
  .link{
    margin-left: 0px;
  }

}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown {
  /* //position: relative; */
  display: inline-block;
  width: 170px;
  background-color: #e1b6b4;
  /* padding: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.dropdown-content a {
  text-align: center;
  color: black;
  padding: 6px 6px;
  font-size: large;
  text-decoration: none;
  display: block;
  background-color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content a {
  margin: 0px;
}

.dropdown:hover .dropdown-content {
  display: block;
  background-color: white;
}

.dropdown:hover .dropbtn {
  background-color: #e1b6b4;
  padding: 0px;
}

.css-1honrdw-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day {
  border-radius: 0% !important;
}

.css-1v8yqdb-MuiPickersArrowSwitcher-root span {
  font-weight: 700 !important;
}


.react-calendar {
  width: 100%;
  max-width: 100%;
  height: 330px;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation {
  height: 50px;
  margin-bottom: 2em
}

.react-calendar__navigation button {
  color: #d4a713;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

/* .react-calendar__tile {
  width: 45px;
  height: 45px;
} */

abbr[title] {
  text-decoration: none;
}

/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #d4a713;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #d4a71333;
  border-radius: 6px;
  font-weight: bold;
  color: #d4a713;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #d4a71333;
  border-radius: 6px;
  font-weight: bold;
  color: #d4a713;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #d4a713;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #d4a713;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #eafc9c;
  color: #d4a713;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #d4a713;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #d4a713;
  color: white;
}

.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}



::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(105, 196, 219);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(105, 196, 219);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 196, 219);
}

.labelsGrid {
  padding-top: 0px !important;  
  padding-bottom: 0.8px !important;
}

.labelsGrid svg {
  /* height: min-content !important; */
  height: 44.9px;
}

.labelsDialog {
  min-width: 600px !important;
}