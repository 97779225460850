.divs {
    border: 2px solid gray;
    width: 300px;
    height: 400px;
    padding: 5px 5px 5px 5px;
}

@media only screen and (max-width: 500px) {
    .btn {
        margin: auto;
    }
}

.btn {
    background: #e1b6b4;
    margin: 20px;
    float: right
}


.cursor-pointer {
    cursor: pointer;
}

.cursor-no-allowed {
    cursor: not-allowed;
}