.text-container{
 padding-left: 12%;
 padding-right: 12%;
}
.orderd-list{
    font-size: 22px;
    font-weight: bold; 
}
.order-list-header{
  font-size: 25px;
  font-weight:bold; 
}
.orderd-list p{
    font-size: 20px;
    word-spacing: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
.orderd-list-item{
 margin-top: 3%;
}

.unorder-list{
  list-style-type: disc;
  list-style-position: inside;
}

.unorder-list-item{

}