.upper-text{
    font-size: 20px;
    margin: 10px;
    padding: 18px;
    line-height: 2em;
    display: flex;
    font-weight: lighter;
    text-align: center;
  }
.lower-text{
    margin: auto;
    padding: 10px;
    width: 70%;
    font-size: 22px;
    font-weight: bolder;
    line-height: 2em;
    color: black;
    text-align: center;
}
.note {
    font-weight: bolder;
    font-size: 16px;
    line-height: 2em;
    color: black;
    margin-top: 6px;
}
.lower-button{
    margin: 80px 30px;
}
.lower-button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight:bolder;
    font-style: italic;
}
.hier-button :hover{
    color: black;
}

.font-bold{
    font-weight: bolder;
}

@media only screen and (max-width: 900px) {
  
    .upper-text{
        font-size: 18px;
        margin: 10px;
        padding: 5px;
        line-height: 2em;
        display: flex;
        text-align:center;
    }
    .note {
        font-size: 12px;
    }
    .lower-button{
        margin: 60px 30px;
    }
    .lower-button-text {
        font-size: 18px;
        font-weight:bolder;
        text-align: center;
    }
    
}
  
  @media only screen and (max-width: 570px) {
    .upper-text{
        font-size: 15px;
        margin: 5px;
        padding: 5px;
        line-height: 2em;
        display: flex;
        font-weight: lighter;
        text-align:center;
      }  
      .lower-text{
        width: 100%;
        font-size: 20px;
        font-weight: bolder;
        line-height: 1.5em;
        color: black;
        text-align: center;
        font-weight: bold;
    }
    .note {
        font-size: 12px;
    }
    .lower-button{
        margin: 50px 30px;
    }
}
  