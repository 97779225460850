.spinner-container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.spinner {
  border: 4px solid rgba(104, 108, 125, 0.3);
  border-top: 4px solid #686c7d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@media only screen and (max-width: 400px) {
  .spinnerText {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
